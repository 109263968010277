export const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(212,217,227,0.75)'
    },
    content: {
        top: '20%',
        left: '50%',
        border: '10px',
        backgroundColor: 'rgba(250,226,226,0.91)',
        right: '50%',
        bottom: 'auto',
        overflow: 'hidden',
        width: '50%',
        height: '30%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};