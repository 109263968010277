//add header from response to token
//get token from local storage to use in downstream calls
import {getCookie, removeCookie, setCookie} from "../CookieHandler";

export const TOKEN_KEY = "access_token"


export const setTokenCookie = (accessToken) => {
    setCookie(TOKEN_KEY, accessToken)
}

export const getTokenCookie = () => {
    return getCookie(TOKEN_KEY)
}

export const removeTokenCookie = () => {
    removeCookie(TOKEN_KEY)
}

