const AUTH_BASE_URL = "https://auth-service-7oeaibvtua-uc.a.run.app"
// const AUTH_BASE_URL = "http://localhost:8080"
 //const AUTH_BASE_URL = "http://localhost:3001"
export const LOGIN_URL = AUTH_BASE_URL + "/v1/access/login"
export const USER_URL = AUTH_BASE_URL + "/v1/user"
export const FORGOTTEN_PASSWORD_URL = USER_URL + "/forgottenPassword"
export const NEW_PASSWORD_URL = USER_URL + "/newPassword"
export const VERIFY_URL = USER_URL + "/verify"
export const NEW_VERIFICATION_CODE_URL = VERIFY_URL + "/newCode"
//export const ITEM_BASE_URL = "http://localhost:9001"
export const ITEM_BASE_URL = "https://item-service-7oeaibvtua-uc.a.run.app"
export const ITEM_ADD_URL = ITEM_BASE_URL + "/v1/item"
export const WATCHLIST_ADD_URL = ITEM_BASE_URL + "/v1/list/watchlist"

export const LIST_URL = ITEM_BASE_URL + "/v1/list"