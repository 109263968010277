import Axios from "axios";
import {setupCache} from 'axios-cache-interceptor';
import {getTokenCookie} from "./token/TokenHandling";

export const TIMEOUT = 20000;

const axios = setupCache(Axios);
export const axiosPostLogin = async (url, data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    //Do something with the input
    let isError = null;
    let response = null;
    console.log(url)
    await axios.post(url, data, {timeout: TIMEOUT, headers: headers})
        .then(function (serverResponse) {
            response = handleResponseSuccess(serverResponse);
            isError = false;
        })
        .catch(function (error) {
            response = handleResponseError(error)
            isError = true;
        })
        .then(function () {
        });
    return {isError, response};
};

export const axiosPostJson = async (url, data) => {
    const headers = {
        'Content-Type': 'application/json'
    }
    //Do something with the input
    let reqError;
    let reqRespond;
    await axios.post(url, data, {timeout: TIMEOUT, headers: headers})
        .then(function (response) {
            reqRespond = handleResponseSuccess(response);
            reqError = false;
        })
        .catch(function (error) {
            reqRespond = handleResponseError(error)
            reqError = true;
        })
        .then(function () {
        });
    return {reqError, reqRespond};
};


export const axiosPostAuth = async (url, data) => {

    let token = getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    //Do something with the input
    let reqError = false;
    let reqRespond = null;
    console.log(url)
    await axios.post(url, data, {timeout: TIMEOUT, headers: headers})
        .then(function (response) {
            reqRespond = handleResponseSuccess(response);
            reqError = false;
        })
        .catch(function (error) {
            reqRespond = handleResponseError(error)
            reqError = true;
        })
        .then(function () {
        });
    return {reqError, reqRespond};
};

export const axiosPut = async (url, data) => {

    let token = getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    //Do something with the input
    let reqError = false;
    let reqRespond = null;
    await axios.put(url, data, {timeout: TIMEOUT, headers: headers})
        .then(function (response) {
            reqRespond = handleResponseSuccess(response);
            reqError = false;
        })
        .catch(function (error) {
            reqRespond = handleResponseError(error)
            reqError = true;
        })
        .then(function () {
        });
    return {reqError, reqRespond};
};

export const axiosDelete = async (url) => {

    let token = getToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }
    //Do something with the input
    let reqError = false;
    let reqRespond = null;
    await axios.delete(url, {timeout: TIMEOUT, headers: headers})
        .then(function (response) {
            reqRespond = handleResponseSuccess(response);
            reqError = false;
        })
        .catch(function (error) {
            reqRespond = handleResponseError(error)
            reqError = true;
        })
        .then(function () {
        });
    return {reqError, reqRespond};
};

export const axiosGetAuth = async (url) => {
    let reqError = false;
    let reqRespond = null;
    let token = getToken();
    const headers = {
        'Authorization': token
    }
    await axios.get(url, {timeout: TIMEOUT, headers: headers})
        .then(function (response) {
            reqRespond = handleResponseSuccess(response);
            reqError = false;
        })
        .catch(function (error) {
            reqRespond = handleResponseError(error)
            reqError = true;
        })
        .then(function () {
        });
    return {reqError, reqRespond};
};


export const handleResponseError = (error) => {
    let reqRespond = null;
    if (error.response) {
        let errorResponse = error.response
        reqRespond = {
            data: errorResponse.data,
            status: errorResponse.status,
            headers: errorResponse.headers
        }
    } else if (error.request) {
        reqRespond = {requestError: error.request}
    }
    return reqRespond;
}

export const getToken = () => {
    return "Bearer " + getTokenCookie();
    //Todo if null/undefined/0 then return to login with alert or error message
}
export const handleResponseSuccess = (response) => {
    return response;
}