import Cookies from 'universal-cookie';

export const setCookie = (cookieName, cookieValue) => {
    const cookies = new Cookies({path: '/', secure: true});
    cookies.set(cookieName, cookieValue);
}

export const getCookie = (cookieName) => {
    const cookies = new Cookies({path: '/', secure: true});
    return cookies.get(cookieName);
}

export const removeCookie = (cookieName) => {
    const cookies = new Cookies({path: '/', secure: true});
    cookies.remove(cookieName);
}

