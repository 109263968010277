import React from 'react'
import {Link} from "react-router-dom";

const LinkElement = (props) => {
    let nestedElem = props.elements
    return (<div id={props.id}>
        <Link to={props.to}>
            {nestedElem}
        </Link>
    </div>);
}

export default LinkElement;